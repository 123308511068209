import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import LabImages from '../AboutUs/LabImages';
import '../body.css';

/*SECCION INICIO*/

const Home = () => {
    return (
        <>
            <div id="home" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div className="content-container">
                    <p className="titulo">¿Qué es UX?</p>
                    <div id="queesux-container">
                        <div className="en-linea espaciado centrado-vertical">
                            
                            <div>
                                <p className="contenido">
                                El término en inglés User Experience (abreviado como UX) se refiere al área interdisciplinaria que estudia el conjunto de factores que repercuten en la interacción con un servicio o producto por parte de usuarias y usuarios (Hassan-Montero, 2015).  Hace alusión a cómo se siente una persona al interactuar con un sistema,  el cual puede ser una página web, una aplicación, un programa, o en general, cualquier forma de interacción entre personas y máquinas.
                                </p>
                                <p className="contenido">
                                La norma ISO 9241-2010 sobre diseño centrado en las personas indica que “la experiencia de usuario son las percepciones y respuestas de una persona como resultado del uso o de la anticipación del uso de un producto, sistema o servicio. [...] La experiencia de usuario incluye todas las emociones, creencias, preferencias, percepciones, respuestas físicas y fisiológicas, comportamientos y logros de los usuarios que ocurren antes, durante y después del uso (Organización Internacional de Normalización, 2019).
                                </p>
                                <p className="contenido">
                                UX va más allá de darle a los clientes lo que dicen que quieren. UX engloba diferentes ámbitos y se construye con una combinación entre ingeniería, marketing, diseño gráfico e industrial, y diseño de interacción.
                                </p>
                            </div>
                            <img className="queesux-image" src="./Que-es-UX.png" alt="Imagen definición UX"/>
                        </div> 
                    </div>    
                </div>
                <div className="content-container">
                    <p className="titulo">Laboratorio</p>
                    <LabImages/> {/*COMPONENTE ABOUTUS-LABIMAGES*/}
                </div>
                <div className="content-container">
                    <p className="titulo">Redes sociales</p>
                    <div id="rrss-container">
                        <div className="margin-left-130 margin-right-130">
                            <div className="en-linea espaciado margin-top-20">
                                <a href="https://www.instagram.com/lab.ux.usm/" className="rrss-a" target="_blank" rel='noreferrer noopener'>
                                    <div className="rrss instalabux">
                                        <div>
                                            <div className="logo-insta">
                                                <i class="fab fa-instagram"/>
                                            </div>
                                            <div className="texto-insta">
                                                @lab.ux.usm
                                            </div>
                                            <div className="en-linea ir-insta">
                                                Ir a Instagram
                                                <i class="fas fa-arrow-right margin-left-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/informaticausm/" className="rrss-a" target="_blank" rel='noreferrer noopener'>
                                    <div className="rrss instadi">
                                        <div>
                                            <div className="logo-insta">
                                                <i class="fab fa-instagram"/>
                                            </div>
                                            <div className="texto-insta">
                                                @informaticausm
                                            </div>
                                            <div className="en-linea ir-insta">
                                                Ir a Instagram
                                                <i class="fas fa-arrow-right margin-left-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/usantamaria/" className="rrss-a" target="_blank" rel='noreferrer noopener'>
                                    <div className="rrss instausm">
                                        <div>
                                            <div className="logo-insta">
                                                <i class="fab fa-instagram"/>
                                            </div>
                                            <div className="texto-insta">
                                                @usantamaria
                                            </div>
                                            <div className="en-linea ir-insta">
                                                Ir a Instagram
                                                <i class="fas fa-arrow-right margin-left-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;