import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION HISTORIA*/

const Historia = () => {
    return (
        <>
            <div id="historia" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div id="historia-container" className="content-container">
                    <p className="titulo">Historia</p>
                    <div className="timeline">
                        <div className="beggin-timeline"></div>
                        <div className="en-linea">
                            <div className="time-container">
                                <img className="time" src="./timeline1.png" alt=""/>
                                <img className="time-s" src="./timeline1s.png" alt=""/>
                            </div>
                            <p className="contenido">
                            En enero de 2020, la Universidad Técnica Federico Santa María (USM) y Scotiabank lanzaron una Alianza Académica para  la Transformación Digital. La iniciativa es liderada por el Departamento de Informática y se estima que beneficiará a 6.000 estudiantes en cinco años.
                            </p>
                        </div>
                        <div className="en-linea">
                            <div className="time-container">
                                <img className="time" src="./timeline2.png" alt=""/>
                                <img className="time-s" src="./timeline2s.png" alt=""/>
                            </div>
                            <p className="contenido">
                            La Alianza busca fortalecer cinco líneas de trabajo: computación centrada en personas, ciberseguridad, inteligencia artificial, desarrollo de habilidades blandas, y mujeres en STEM.
                            </p>
                        </div>
                        <div className="en-linea">
                            <div className="time-container">
                                <img className="time" src="./timeline3.png" alt=""/>
                                <img className="time-s" src="./timeline3s.png" alt=""/>
                            </div>
                            <p className="contenido">
                            Lab UX es una piedra angular de la contribución de la alianza al desarrollo de la línea en computación centrada en personas en nuestra casa de estudios y en Chile.  Esta línea es co-liderada por las académicas de Casa Central, Cecilia Reyes y Claudia López. Ambas han articulado una colaboración cercana para el diseño y conducción del Lab UX con la académica Lioubov Dombrovskaia y el académico Leonardo Madariaga, quienes trabajan en el campus San Joaquin. Lab UX tendrá su primera instalación en la Casa Central en el 2021 y su segunda instalación en el campus San Joaquín en el 2022.
                            </p>
                        </div>
                        <div className="en-linea">
                            <div>
                                <div className="time-container height-50">
                                    <img className="time" src="./timeline4.png" alt=""/>
                                    <img className="time-s" src="./timeline4s.png" alt=""/>
                                </div>
                                <div className="time-container height-50">
                                    <img className="time" src="./timeline5.png" alt=""/>
                                    <img className="time-s" src="./timeline5s.png" alt=""/>
                                </div>
                            </div>
                            <p className="contenido">
                            Para idear el diseño del Lab UX y definir los requerimientos que debiera cumplir se realizó un diseño participativo con un equipo asesor externo de ex-estudiantes de la USM que se desempeñan en área UX actualmente. También se trabajó con profesores, estudiantes y memoristas tanto de Ingeniería Civil Informática como de Ingeniería en Diseño de Productos.
                            </p>
                        </div>
                        <div className="en-linea">
                            <div className="time-container">
                                <img className="time" src="./timeline6.png" alt=""/>
                                <img className="time-s" src="./timeline6s.png" alt=""/>
                            </div>
                            <p className="contenido">
                            Actualmente se trabaja en la construcción del Lab UX USM, diseñando tanto su espacio físico como el virtual para llevar a cabo experiencias de aprendizaje de diseño centrado en personas, evaluación de usabilidad y estudio de la experiencia de usuarias y usuarios con tecnologías para diversos contextos de aplicación.
                            </p>
                        </div>
                        <div className="end-timeline"></div>
                    </div>     
                </div>
            </div>
        </>
    );
}

export default Historia;