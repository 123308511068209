import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import LabImages from './LabImages';
import '../body.css';


/*SECCION LABORATORIO*/

const Laboratorio = () => {

    return (
        <>
            <div id="laboratorio" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div id="misionvision-container">
                    <div className="content-container en-linea espaciado">
                        <div className="misionvision-width">
                            <p className="titulo">Visión</p>
                            <div className="misionvision-content en-linea">
                                <div className="logo-container">
                                    <img className="logo" src="./Vision.png" alt="Logo visión"/>
                                </div>
                                <div className="margin-left-30 centrado-vertical">
                                    <p className="contenido">
                                    Liderar el cambio hacia una cultura tecnológica centrada en el bienestar de las personas y la búsqueda del desarrollo sostenible e inclusivo de la sociedad.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="misionvision-width">
                            <p className="titulo">Misión</p>
                            <div className="misionvision-content en-linea">
                                <div className="logo-container">
                                    <img className="logo" src="./Mision.png" alt="Logo misión"/>
                                </div>
                                <div className="margin-left-30 centrado-vertical">
                                    <p className="contenido">
                                    Fortalecer la formación e investigación multidisciplinaria en el desarrollo y evaluación de tecnologías centradas en las necesidades de las personas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="laboratorio-descr" className="content-container">
                    <p className="titulo">Descripción del laboratorio</p>
                    <p className="contenido">
                    El Laboratorio UX USM permite llevar a cabo técnicas de investigación y evaluación de los procesos de diseño 
                    centrado en las personas: desde sesiones en grupo basadas en metodologías del design thinking, 
                    hasta test de usabilidad con usuarios finales.
                    </p>
                    <p className="contenido">
                    El Laboratorio UX USM también se encuentra disponible como servicio de arriendo de laboratorio.
                    </p>
                    <div className="margin-left-30 margin-top-40">
                        <LabImages/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Laboratorio;