import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION EQUIPO*/

const Equipo = () => {
    return (
        <>
            <div id="equipo" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div id="equipo-container" className="content-container">
                        <p className="titulo">Equipo</p>
                        <div className="margin-left-150">
                            <p className="subtitulo">Directorio</p>
                            {/*-----------PERFILES DOCENTES-----------*/}
                            {/*-----------FILA-----------*/}
                            <div className="en-linea">
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/cecilia-reyes-covarrubias/">
                                            <img className="profile-picture" src="./Profile-CeciliaReyes.jpg" alt="Profesora Cecilia Reyes"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="profile-data">
                                            <p><b>Cecilia Reyes</b></p>
                                            <p>Co-directora Lab UX CC</p>
                                            <p>reyes@inf.utfsm.cl</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/claudia-lopez-0218164/">
                                            <img className="profile-picture" src="./Profile-ClaudiaLopez.jpg" alt="Profesora Claudia Lopez"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="profile-data">
                                            <p><b>Claudia López</b></p>
                                            <p>Co-directora Lab UX CC</p>
                                            <p>claudia.lopez@inf.utfsm.cl</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/lioubov/">
                                            <img className="profile-picture" src="./Profile-LiubovDombrovskaia.png" alt="Profesora Liubov Dombrovskaia"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="profile-data">
                                            <p><b>Liubov Dombrovskaia</b></p>
                                            <p>Directora Lab UX SJ</p>
                                            <p>liuba@inf.utfsm.cl </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/leonardomadariaga/">
                                            <img className="profile-picture" src="./Profile-LeonardoMadariaga.jpg" alt="Profesor Leonardo Madariaga"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="profile-data">
                                            <p><b>Leonardo Madariaga</b></p>
                                            <p>Asesor IDP</p>
                                            <p>leonardo.madariaga@usm.cl</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="subtitulo">Equipo de Trabajo</p>
                            {/*-----------PERFILES EQUIPO-----------*/}
                            {/*-----------FILA-----------*/}
                            <div className="en-linea">
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/vmjalfaro/">
                                            <img className="profile-picture" src="./Profile-MacarenaJorquera.jpeg" alt="Ingeniera Macarena Jorquera"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="profile-data">
                                            <p><b>Macarena Jorquera</b></p>
                                            <p>Ingeniera de Proyectos</p>
                                            <p>vmjalfaro@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="perfil">
                                    <div className="profile-picture-container">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/pedro-arriagada-noack/">
                                            <img className="profile-picture" src="./Profile-PedroArriagada.jpg" alt="Pedro Arriagada"/>
                                            <div className="overlay">
                                                <div className="fade">
                                                    <i class="fab fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="profile-data">
                                        <p><b>Pedro Arriagada</b></p>
                                        <p>Practicante Informática</p>
                                        <p>pedro.arriagadan@sansano.usm.cl</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}

export default Equipo;