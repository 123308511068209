import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION METODOLOGIA*/

const Metodologia = () => {
    return (
        <>
            <div id="metodologia" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <p className="titulo">Metodología</p>
                <p className="fecha">22 Marzo 2021</p>
                <p className="subtitulofecha">Design Thinking, Scrum y Lean</p>
                <p className="contenido">La industria del diseño UX en Chile ha tenido un crecimiento importante durante los últimos años, pero no consolidado, aunque ya cuenta con una historia que, supera las dos décadas. En la década del 2010 por ejemplo, gracias a nuevos programas de formación académica y a la inclusión de nuevas metodologías para el diseño (Design Thinking, Scrumm, y Lean), al día de hoy, las empresas de consultoría están proliferando en Chile, tales como: IDA, Medible, Ilógica, 3DIAS y 2Brains.</p>
                <div id="dt-container" className="content-container margin-left-30">
                    <p className="subsubtitulo">¿Qué es el Design Thinking?</p>
                    <div className="en-linea espaciado">
                        <div className="">
                            <p className="contenido">Es un método para generar ideas innovadoras que centra su eficacia en entender y dar solución a las necesidades reales de los usuarios. Proviene de la forma en la que trabajan los diseñadores de producto. De ahí su nombre, que en español se traduce de forma literal como &quot;Pensamiento de Diseño&quot;.</p>
                            <p className="contenido">Se empezó a desarrollar de forma teórica en la Universidad de Stanford en California, EEUU, a partir de los años 70, y su primera aplicabilidad con fines lucrativos como &quot;Design Thinking&quot; la llevó a cabo la consultoría de diseño IDEO. Según IDEO, el Design Thinking &quot;Es una disciplina que usa la sensibilidad y métodos de los diseñadores para hacer coincidir las necesidades de las personas con lo que es tecnológicamente factible y con lo que una estrategia viable de negocios puede convertir en valor para el cliente, así como en una gran oportunidad para el mercado&quot;.</p>
                        </div>
                        <img className="design-thinking-figura" src="./design-thinking-figura.png" alt="Figura explicativa Design Thinking"/>
                    </div>
                    <p className="contenido">El proceso de Design Thinking se compone de cinco etapas y no es lineal. En cualquier momento se puede ir hacia atrás o hacia delante si es oportuno. De esta forma se recolecta mucha información, generando una gran cantidad de contenido. Luego se afina ese contenido hasta desembocar en una solución que cumpla con los objetivos del equipo.</p>
                    <div className="figura-subcontainer">
                        <div className="figura-container">
                            <div className="en-linea">
                                <div className="table">
                                    <img className="design-thinking-proceso" src="./empatia.png" alt="Proceso de empatia Design Thinking"/>
                                    <p className="design-thinking-proceso-definicion">Profunda comprensión de las necesidades de los usuarios y de su entorno. Se generan soluciones consecuentes con sus realidades.</p>
                                </div>
                                <img className="design-thinking-proceso" src="./espacio-1.png" alt=""/>
                                <div className="table">
                                    <img className="design-thinking-proceso" src="./definicion.png" alt="Proceso de Design Thinking"/>
                                    <p className="design-thinking-proceso-definicion">Durante esta etapa se criba la información recopilada durante la fase de Empatía y se queda con lo que realmente aporta valor.</p>
                                </div>
                                <img className="design-thinking-proceso" src="./espacio-2.png" alt=""/>
                                <div className="table">
                                    <img className="design-thinking-proceso" src="./ideacion.png" alt="Proceso de Design Thinking"/>
                                    <p className="design-thinking-proceso-definicion">Tiene como objetivo la generación de un sinfín de opciones.  En esta fase, las actividades favorecen el pensamiento y se eliminan los juicios de valor.</p>
                                </div>
                                <img className="design-thinking-proceso" src="./espacio-3.png" alt=""/>
                                <div className="table">
                                    <img className="design-thinking-proceso" src="./prototipado.png" alt="Proceso de Design Thinking"/>
                                    <p className="design-thinking-proceso-definicion">En esta etapa se vuelven las ideas realidad. Construir prototipos hace las ideas palpables y ayuda a visualizar las posibles soluciones.</p>
                                </div>
                                <img className="design-thinking-proceso" src="./espacio-4.png" alt=""/>
                                <div className="table">
                                    <img className="design-thinking-proceso" src="./testeo.png" alt="sProceso de Design Thinking"/>
                                    <p className="design-thinking-proceso-definicion">Etapa en que se prueban los prototipos con los usuarios. Ayuda a identificar mejoras significativas, fallos a resolver y  posibles carencias.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="creacion" className="content-container">
                    <p className="fecha">22 Marzo 2021</p>
                    <p className="subtitulofecha">¿Cómo se creó el LAB UX USM?</p>
                    <p className="contenido">La creación de un Laboratorio UX es un proyecto que se realizó conjuntamente en los departamentos de Ingeniería Informática e Ingeniería en Diseño de Productos, bajo el marco del programa para la Transformación Digital, en alianza académica entre Scotiabank y la Universidad Técnica Federico Santa María.</p>
                    <p className="contenido">La metodología implementada para llevar a cabo el desarrollo de este proyecto está basada en el método de Pahl & Beitz (2007) (figura 1), donde se aplicaron sus etapas de forma simplificada, apoyándose también en la metodología de “Diseño y desarrollo de productos” propuesta por K.Ulrich y S. Eppinger (2013) (figura 2), con el fin de obtener información global y relevante para las soluciones propuestas. Cabe destacar que ambas metodologías se adaptaron según las necesidades del proyecto.</p>
                    <div className="figura-subcontainer">
                        <div className="figura-container">
                            <img className="metodo-pahl" src="./metodo-pahl.png" alt="Diagrama metodología Pahl"/>
                            <p className="caption">Figura 1: &quot;Método de Pahl &amp; Beitz&quot;</p>
                        </div>
                    </div>
                    <div className="figura-subcontainer">
                        <div className="figura-container">
                            <img className="ulrich-eppinger" src="./ulrich-eppinger.png" alt="Metodología de diseño y desarrollo de productos"/>
                            <p className="caption">Figura 2: &quot;Metodología de Ulrich &amp; Eppinger&quot;</p>
                        </div>
                    </div>
                    <p className="contenido">En la figura 3 se muestra las distintas etapas del proceso de diseño, que llevaron a la implementación de una solución acorde a los requerimientos del Lab UX USM. Destaca en la segunda etapa de diseño conceptual, la Red de Problemas de OTSM-TRIZ, propuesta por Nikholai Khomenko (2007), como una herramienta que anticipa problemas al momento de generar el diseño de un producto o servicio.</p>
                    <div className="figura-subcontainer">
                        <div className="figura-container">
                            <img className="proceso-diseño" src="./proceso-diseño.png" alt="Etapas del proceso de diseño"/>
                            <p className="caption">Figura 3: &quot;Etapas del proceso de diseño&quot;</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Metodologia;