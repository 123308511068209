import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION QUE HACEMOS*/

const WhatWeDo = () => {
    return (
        <>
            <div id="whatwedo" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div className="content-container">
                    <p className="titulo">Qué hacemos</p>
                    <div id="whatwedo-container" className="en-linea espaciado margin-top-20">
                        <div className="margin-left-65 margin-right-65 en-linea espaciado">
                            <div className="width-45">
                                {/*PRIMERA COLUMNA*/}
                                <img className="whatwedo-picture" src="./diseño-interaccion.png" alt="Logo diseño interacción"/>
                                <p className="whatwedo-title mayusculas "><b>diseño interacción</b></p>
                                <div className="en-linea espaciado">
                                    <img className="whatwedo-subpicture" src="./diagrama-flujo.png" alt="Logo diagrama de flujo"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">diagrama de flujo</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Visualiza los pasos que sigue un sistema o persona para realizar una serie de tareas.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea espaciado margin-top-20">
                                    <img className="whatwedo-subpicture" src="./partitura-interaccion.png" alt="Logo partitura de interacción"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">partitura de interacción</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Articula las relaciones entre las personas, la interfaz visual y las respuestas del sistema.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea espaciado margin-top-20">
                                    <img className="whatwedo-subpicture" src="./mapa-navegacion.png" alt="Logo mapa de navegación"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">mapa de navegación</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Representa la estructura general de un sistema. Distribuye, organiza y jerarquiza el contenido que se verá en la pantalla de un producto o servicio digital.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea espaciado margin-top-20">
                                    <img className="whatwedo-subpicture" src="./diseño-interfaces.png" alt="Logo diseño de interfaces"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">diseño de interfaces</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Asegura que la interacción persona-sistema se pueda efectuar de manera simple, facil e intuitiva.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="width-45">
                                {/*SEGUNDA COLUMNA*/}
                                <img className="whatwedo-picture" src="./diseño-investigacion.png" alt="Logo diseño investigación"/>
                                <p className="whatwedo-title mayusculas"><b>diseño investigacion</b></p>
                                <div className="en-linea">
                                    <img className="whatwedo-subpicture" src="./estudio-usuario.png" alt="Logo estudio de usuario"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">estudio de usuarios</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Identifica a las personas que interactuan con la marca en sus canales digitales.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea margin-top-20">
                                    <img className="whatwedo-subpicture" src="./definicion-arquetipos.png" alt="Logo definición de arquetipos"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">definición de arquetipos</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                        Ayuda a visualizar quién es el usuario para el que se está diseñando.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea margin-top-20">
                                    <img className="whatwedo-subpicture" src="./test-usabilidad.png" alt="Logo test de usabilidad"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">test de usabilidad</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                        Evalúa un producto o servicio digital mediante pruebas con usuarios finales.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea margin-top-20">
                                    <img className="whatwedo-subpicture" src="./evaluacion-heuristica.png" alt="Logo evaluación heuristica"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">evaluación heuristica</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                        Identifica los errores de usabilidad y muestra oportunidades de optimización.
                                        </p>
                                    </div>
                                </div>
                                <div className="en-linea espaciado margin-top-20">
                                    <img className="whatwedo-subpicture" src="./customer-journey.png" alt="Logo customer journey"/>
                                    <div className="margin-left-20">
                                        <div className="whatwedo-subtitle mayusculas">customer journey map</div>
                                        <p className="contenido pad-left-0 mar-top-0 mar-bot-0">
                                            Visualiza las fases que sigue una persona en su interacción con un servicio de principio a fin y a través de diversos canales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhatWeDo;