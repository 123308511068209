import React from 'react';
import '../body.css';

/*IMAGENES DEL LABORATORIO MOSTRADAS EN SECCIONES LABORATORIO E INICIO*/

const LabImages = () => {

    const onValueChange = event => {
        if (event.target.value === "todo") {
            document.getElementById("lab").className = "display";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "display";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "colaborativo"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "display";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "display";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "entrevistas"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "display";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "display";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "cafeteria"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "display";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "display";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "seguimiento"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "display";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "display";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "observacion"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "display";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "display";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "test_card"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "display";
            document.getElementById("tv").className = "nodisplay";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "display";
            document.getElementById("tv2").className = "nodisplay";
        }
        else if (event.target.value === "videojuegos"){
            document.getElementById("lab").className = "nodisplay";
            document.getElementById("colab").className = "nodisplay";
            document.getElementById("entre").className = "nodisplay";
            document.getElementById("cafe").className = "nodisplay";
            document.getElementById("area-seg").className = "nodisplay";
            document.getElementById("puesto-obs").className = "nodisplay";
            document.getElementById("act-grupo").className = "nodisplay";
            document.getElementById("tv").className = "display";
            document.getElementById("lab2").className = "nodisplay";
            document.getElementById("colab2").className = "nodisplay";
            document.getElementById("entre2").className = "nodisplay";
            document.getElementById("cafe2").className = "nodisplay";
            document.getElementById("area-seg2").className = "nodisplay";
            document.getElementById("puesto-obs2").className = "nodisplay";
            document.getElementById("act-grupo2").className = "nodisplay";
            document.getElementById("tv2").className = "display";
        }
    }

    return (
        <>
            <p className="contenido secciones-pc">Haz click en las secciones para conocerlas</p>
            <div id="lab-image-container" className="en-linea espaciado centrado-vertical">
                <div className="cel">
                    <img id="lab2" className="display" src="M_laboratorio.png" alt="Imagen laboratorio completo"></img>
                    <img id="colab2" className="nodisplay" src="M_colab_colaborativa.png" alt="Imagen área colaborativa"></img>
                    <img id="entre2" className="nodisplay" src="M_colab_entrevistas.png" alt="Imagen área de entrevistas"></img>
                    <img id="cafe2" className="nodisplay" src="M_colab_cafeteria.png" alt="Imagen área de cafetería"></img>
                    <img id="area-seg2" className="nodisplay" src="M_obs_seguimiento.png" alt="Imagen área de seguimiento"></img>
                    <img id="puesto-obs2" className="nodisplay" src="M_obs_observacion.png" alt="Imagen área de observación"></img>
                    <img id="act-grupo2" className="nodisplay" src="M_test_testing.png" alt="Imagen área de card sorting"></img>
                    <img id="tv2" className="nodisplay" src="M_test_videojuegos.png" alt="Imagen área de testing videojuegos"></img>
                </div>
                <p className="contenido secciones-cel">Haz click en las secciones para conocerlas</p>
                <div id="radio-buttons"  onChange={onValueChange.bind(this)}>
                    <label className="container margin-left-20">
                        <input id="todo" type="radio" value="todo" name="lab" defaultChecked="true"/>
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Laboratorio</p>
                    </label>
                    <p className="secciones-lab">Sala de trabajo colaborativo</p>
                    <label className="container margin-left-20">
                        <input id="colaborativo" type="radio" value="colaborativo" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Área colaborativa</p>
                    </label>
                    <label className="container margin-left-20">
                        <input id="entrevistas" type="radio" value="entrevistas" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Área de entrevistas</p>
                    </label>
                    <label className="container margin-left-20">
                        <input id="cafeteria" type="radio" value="cafeteria" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Cafetería</p>
                    </label>
                    <p className="secciones-lab">Sala de observación</p>
                    <label className="container margin-left-20">
                        <input id="seguimiento" type="radio" value="seguimiento" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Área de seguimiento</p>
                    </label>
                    <label className="container margin-left-20">
                        <input id="observacion" type="radio" value="observacion" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Área de observación</p>
                    </label>
                    <p className="secciones-lab">Sala de testing</p>
                    <label className="container margin-left-20">
                        <input id="test_card" type="radio" value="test_card" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Área de testing y card sorting</p>
                    </label>
                    <label className="container margin-left-20">
                        <input id="videojuegos" type="radio" value="videojuegos" name="lab" />
                        <span class="checkmark"></span>
                        <p className="text-img-lab">Testing de videojuegos</p>
                    </label>
                </div>
                <div className="pc">
                    <img id="lab" className="display" src="M_laboratorio.png" alt="Imagen laboratorio completo"></img>
                    <img id="colab" className="nodisplay" src="M_colab_colaborativa.png" alt="Imagen área colaborativa"></img>
                    <img id="entre" className="nodisplay" src="M_colab_entrevistas.png" alt="Imagen área de entrevistas"></img>
                    <img id="cafe" className="nodisplay" src="M_colab_cafeteria.png" alt="Imagen área de cafetería"></img>
                    <img id="area-seg" className="nodisplay" src="M_obs_seguimiento.png" alt="Imagen área de seguimiento"></img>
                    <img id="puesto-obs" className="nodisplay" src="M_obs_observacion.png" alt="Imagen área de observación"></img>
                    <img id="act-grupo" className="nodisplay" src="M_test_testing.png" alt="Imagen área de card sorting"></img>
                    <img id="tv" className="nodisplay" src="M_test_videojuegos.png" alt="Imagen área de testing videojuegos"></img>
                </div>
            </div>
        </>
    );
}

export default LabImages;
