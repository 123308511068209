import React from 'react';
import './headfooter.css';

/*import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
*/

//COMPONENTE PIE DE PAGINA

const Footer = () => {
    return (
        <div id="footer" className="footer-container">
            <div className="en-linea espaciado">
                {/*DIRECCIÓN DEL LABORATORIO*/}
                <div className="footer-content">
                    <p className="footer-titulo"><b>¿Dónde estamos?</b></p>
                    <p className="footer-campus"><b>Campus Casa Central Valparaiso</b></p>
                    <a className="footer-direccion" href="https://goo.gl/maps/qAM3V77MVdyhmjjo6">
                        Los Placeres 466, Valparaíso
                    </a>
                </div>
                {/*DIRECCIÓN DE CORREO DEL LABORATORIO*/}
                <div className="footer-content">
                    <p className="footer-titulo"><b>¡Contáctanos!</b></p>
                    <p className="footer-campus"><b>Campus Casa Central Valparaiso</b></p>
                    <p className="footer-direccion">
                        labux.usm@gmail.com | labux@inf.utfsm.cl
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
