import React from 'react';
import './headfooter.css';

//COMPONENETE DE LA CABECERA DE LA PAGINA (BARRA VERDE SUPERIOR)

const Header = () => {
    return (
        <div className="header-container">
            <ul className="header">
                <li className="ruta">
                    <a href="/"> Lab UX </a>
                </li>
            </ul>
        </div>
    );
}

export default Header;
