import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION APRENDE MÁS*/

const LearnMore = () => {
    return (
        <>
            <div id="learnmore" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div id="learnmore-body" className="cuerpito">
                <div id="aprendemas-servicios" className="content-container">
                    <p className="fecha">15 Marzo 2021</p>
                    <p className="titulo">Benchmarking de servicios UX</p>
                    <p className="contenido">Al comenzar con la creación e implementación del Lab UX USM, se realizó un benchmarking de los principales servicios UX existentes en el mercado chileno. Se hizo un análisis comparativo de productos o servicios disponibles. Nuestro objetivo era contar con puntos de referencia antes de definir los servicios que ofrecería nuestro UX Lab.</p>
                    <p className="contenido">El siguiente mapa conceptual visualiza el resultado de este análisis. Los servicios ofrecidos se pueden clasificar en dos grandes grupos: (1) diseño y desarrollo de productos, usualmente de software y (2) diseño de experiencias e investigación. Ambos buscan crear u optimizar los negocios digitales de sus clientes. El segundo busca ahondar en la transformación digital de los negocios.</p>
                    <div className="aprendemas-servicios"></div> {/*MAPA DE SERVICIOS*/}
                </div>
                <div id="aprendemas-logos" className="content-container">
                    <p className="fecha">15 Marzo 2021</p>
                    <p className="titulo">Herramientas para el mundo UX</p>
                    <p className="contenido">Existen diversas herramientas que sirven para obtener datos e información sobre sitios web, en relación a analítica web, auditorías de usabilidad, mapas de calor y grabación de sesiones, test a usuarios, feedback de clientes, entre otras. Las más recomendadas en el mundo UX se presentan en la gráfica siguiente.</p>
                    <div className="margin-left-30">
                        <div id="fila-grupo">
                            <div className="en-linea espaciado margin-bot-20">
                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Analítica web</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./google-analytics.png" alt="Logo Google Analytics"/>
                                        <img className=" height-60px middle" src="./adobe-analytics.png" alt="Logo Adobe Analytics"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="margin-top-20 height-40px middle" src="./heap.png" alt="Logo Heap"/>
                                        <img className="margin-top-20 height-40px middle" src="./piwik.png" alt="Logo Piwik"/>
                                    </div>
                                </div>

                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Mapas de calor</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-40px margin-auto" src="./clicktale.png" alt="Logo ClickTale"/>
                                        <img className="height-60px middle" src="./crazyegg.png" alt="Logo Crazy Egg"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./clicky.png" alt="Logo Clicky"/>
                                        <img className="height-60px middle" src="./hotjar.png" alt="Logo Hotjar"/>
                                    </div>
                                </div>                  
                            </div>

                            <div className="en-linea espaciado margin-bot-20">
                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Grabación de sesiones</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-50px middle" src="./mouseflow.png" alt="Logo MouseFlow"/>
                                        <img className="height-50px margin-auto" src="./sessioncam.png" alt="Logo SessionCam"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./decibel-insight.png" alt="Logo Decibel Insight"/>
                                        <img className="height-30px margin-auto" src="./camtasia.png" alt="Logo Camtasia"/>
                                    </div>
                                </div>

                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Chat</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./intercom.png" alt="Logo Intercom"/>
                                        <img className="height-60px middle" src="./zopim.png" alt="Logo Zopim"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./olark.png" alt="Logo Olark"/>
                                        <img className="height-60px middle" src="./live-chat.png" alt="Logo LiveChat"/>
                                    </div>
                                </div>                  
                            </div>

                            <div className="en-linea espaciado margin-bot-20">
                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Test y reclutamiento de usuarios</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./usabilityhub.png" alt="Logo UsabilityHub"/>
                                        <img className="height-60px middle" src="./try-my-ui.png" alt="Logo TryMyUI"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./user-testing.png" alt="Logo UserTesting"/>
                                        <img className="height-60px middle" src="./userlytics.jpeg" alt="Logo Userlytics"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-40px margin-top-10 middle" src="./optimal-workshop.jpeg" alt="Logo Optimal Workshop"/>
                                        <img className="height-20px margin-auto" src="./lookback.png" alt="Logo Lookback"/>
                                    </div>
                                </div>

                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Feedback</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./qualaroo.png" alt="Logo Qualaroo"/>
                                        <img className="height-60px middle" src="./user-voice.png" alt="Logo UserVoice"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./kampyle.png" alt="Logo Kampyle"/>
                                        <img className="height-60px middle" src="./usabilla.png" alt="Logo Usabilla"/>
                                    </div>
                                </div>                  
                            </div>

                            <div className="en-linea espaciado">
                                <div className="width-45">
                                    <p id="grupo" className="subtitulo-learnmore">Otras herramientas</p>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./invision.png" alt="Logo InVision"/>
                                        <img className="height-60px middle" src="./miro.png" alt="Logo Miro"/>
                                    </div>
                                    <div className="logos-container width-80 en-linea espaciado">
                                        <img className="height-60px middle" src="./mailchimp.png" alt="Logo Mailchimp"/>
                                        <img className="height-60px middle" src="./zoom.png" alt="Logo Zoom"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="aprendemas-comparativa" className="content-container">
                    <p className="fecha">15 Marzo 2021</p>
                    <p className="titulo">Tabla comparativa de herramientas y plataformas de testeos</p>
                    <p className="contenido">El área de optimización de conversión (CRO por su sigla en inglés, conversion rate optimization) es una disciplina del marketing digital y la analítica web, orientada a mejorar el porcentaje de personas que realizan una acción deseada en un sitio web (Ash, Tim and Page, Rich and Ginty, Maura, 2012). Un método clave de esta área es el A/B testing, con el cual se busca validar hipótesis de optimización a través de la comparación de dos implementaciones de un mismo sitio. Existen herramientas que apoyan la ejecución de A/B testing recolectando y analizando datos de uso. Se puede medir, por ejemplo, el comportamiento de las personas en un sitio web como el número de errores, el tiempo para terminar una transacción, entre otros.</p>
                    <p className="contenido">Existen varias herramientas en el mercado para realizar A/B testing, cada una con sus funcionalidades y características, pero ¿Cómo escoger entre una u otra? Algunas de ellas no se limitan sólo a realizar testeos A/B o testeos multivariable. Algunas ofrecen funciones de personalización para adaptar el contenido a distinto público objetivo, grabar sesiones con usuarios, realizar encuestas, mapas de calor, entre otras.</p>
                    <p className="contenido">A continuación se presenta una tabla comparativa de herramientas de testeos que ofrecen diversos proveedores, cada uno de ellos centrado en su publico objetivo particular. Algunos ofrecen sus servicios a grandes empresas, mientras que otros a pymes o microempresas. Por ello, existen distintos planes y precios, encontrando incluso herramientas gratuitas, como lo son Optimizely o Google Optimize.</p>
                    <div className="aprendemas-comparativa relative margin-left-30"></div>
                </div>
            </div>
        </>
        
    );
}

export default LearnMore;