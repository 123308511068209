import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home/Home';
import LearnMore from './components/LearnMore/LearnMore';
//import Schedule from './components/Schedule/Schedule';
import Stakeholders from './components/Stakeholders/Stakeholders';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import Laboratorio from './components/AboutUs/Laboratorio';
import Metodologia from './components/AboutUs/Metodologia';
import Equipo from './components/AboutUs/Equipo';
import Historia from './components/AboutUs/Historia';
import Footer from './components/HeadandFoot/Footer';
import ScrollToTop from './components/ScrollToTop';

const App = () => {

  return (
    <>
      <ScrollToTop/>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/que-hacemos' component={WhatWeDo}/>
        <Route path='/stakeholders' component={Stakeholders}/>
        {/*ABOUT US*/}
        <Route path='/laboratorio' component={Laboratorio}/>
        <Route path='/metodologia' component={Metodologia}/>
        <Route path='/equipo' component={Equipo}/>
        <Route path='/historia' component={Historia}/>
        {/*--------*/}
        <Route path='/aprende-mas' component={LearnMore}/>
        { /* Seccion eliminada <Route path='/schedule' component={Schedule}/>*/}
      </Switch>
      <Footer/>
    </>
  );

};

export default App;
