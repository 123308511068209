import React from 'react';
import Header from '../HeadandFoot/Header';
import Navegacion from '../HeadandFoot/Navegacion';
import '../body.css';

/*SECCION CON QUIENES TRABAJAMOS*/

const Stakeholders = () => {
    return (
        <>
            <div id="stakeholders" className="header-backg">
                <Header/>
                <Navegacion/>
            </div>
            <div className="cuerpito">
                <div className="content-container">
                    <p className="titulo">Con quién trabajamos</p>
                    <div id="stakeholders-container" className="margin-top-20">
                        <div className="stakeholder-container en-linea">
                            <img className="stakeholder-picture" src="./Docencia.png" alt="Imagen docencia"/>
                            <div className="margin-left-30">
                                <div className="stakeholder-name">
                                    <p>Docencia</p>
                                </div>
                                <div className="contenido">
                                Lab UX apoya a estudiantes, académicos, memoristas e investigadores en el diseño y evaluación de UX para sus actividades curriculares y de investigación.
                                </div>
                            </div>
                        </div>
                        <div className="stakeholder-container en-linea padding-top-30">
                            <img className="stakeholder-picture" src="./Comunidad.png" alt="Imagen comunidad"/>
                            <div className="margin-left-30">
                                <div className="stakeholder-name">
                                    <p>Comunidad</p>
                                </div>
                                <div className="contenido">
                                Lab UX busca involucrarse con la sociedad civil e instituciones públicas y educacionales para desarrollar colaboraciones en el ámbito del diseño y evaluaciones centradas en personas de tecnologías y productos.
                                </div>
                            </div>
                        </div>
                        <div className="stakeholder-container en-linea padding-top-30">
                            <img className="stakeholder-picture" src="./Industria.png" alt="Imagen industria"/>
                            <div className="margin-left-30">
                                <div className="stakeholder-name">
                                    <p>Industria</p>
                                </div>
                                <div className="contenido">
                                Lab UX ofrece servicios de UX, tales como testing de usabilidad, para empresas e instituciones públicas que deseen evaluar la experiencia que ofrecen su tecnologías, productos o servicios.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-container">
                    <p className="titulo">Con quién colaboramos</p>
                    <div id="colaboramos-container">
                        <div className="colaboramos-container en-linea espaciado">
                            <img className="logo-scotiabank" src="/logo-scotiabank.png" alt="Logo Scotiabank"/>
                            <img className="logo-idp" src="/logo-idp.png" alt="Logo departamento de ingeniería en diseño"/>
                            <img className="logo-di" src="/logo-di.png" alt="Logo departamento de informática"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stakeholders;