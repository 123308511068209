import React from 'react';
import { NavLink } from 'react-router-dom';
import "./headfooter.css";

/*import MenuNav from "./MenuNav.js";*/

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Navegacion = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="menu-container">
                <div className="menu espaciado">
                    <a href="/">
                        <img className="logo-usm" src="/logo-usm-blanco.png" alt="Logo USM"/>
                    </a>

                    {/*MOSTRADO EN FORMATO TABLETA O CELULAR*/}
                    {/*<MenuNav/>*/}

                    <div id="nav-button" className="centrado-vertical">
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={handleClick}
                        >
                            <i class="fas fa-bars"></i>
                        </Button>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <NavLink to="/" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Inicio
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/que-hacemos" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    ¿Qué hacemos?
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/stakeholders" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    ¿Con quién trabajamos?
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/laboratorio" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    LAB UX USM
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/metodologia" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Metodología
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/equipo" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Equipo
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/historia" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Historia
                                </MenuItem>
                            </NavLink>
                            <NavLink to="/aprende-mas" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Aprende Más
                                </MenuItem>
                            </NavLink>
                            {/*

                            Se eliminó la sección de Agendar Ahora, ya que no funciona y el agendamiento se hace manualmente.

                            <NavLink to="/schedule" className="link" activeClassName="active_class" exact>
                                <MenuItem
                                    className="link-container"
                                    onClose={handleClose}
                                >
                                    Agenda Hora
                                </MenuItem>
                            </NavLink>
                            */}
                        </StyledMenu>
                    </div>

                    {/*MOSTRADO EN FORMATO WEB*/}
                    <nav id="nav-normal" className="nav">
                        <ul className="links">
                            <li className="link-container">
                                <NavLink
                                    exact
                                    className="link"
                                    activeClassName="active_class"
                                    to="/">
                                        Inicio
                                </NavLink>
                            </li>
                            <li className="link-container">
                                <NavLink
                                    exact
                                    className="link"
                                    activeClassName="active_class"
                                    to="/que-hacemos">
                                        ¿Qué hacemos?
                                </NavLink>
                            </li>
                            <li className="link-container">
                                <NavLink
                                    exact
                                    className="link"
                                    activeClassName="active_class"
                                    to="/stakeholders">
                                        ¿Con quién trabajamos?
                                </NavLink>
                            </li>
                            <li className="link-container">
                                <NavLink
                                    id="sobrenosotros"
                                    className="link"
                                    activeClassName="active_class"
                                    isActive={()=>{
                                        if (window.location.href.indexOf("laboratorio") !== -1 ||
                                            window.location.href.indexOf("mision-vision") !== -1 ||
                                            window.location.href.indexOf("metodologia") !== -1 ||
                                            window.location.href.indexOf("equipo") !== -1 ||
                                            window.location.href.indexOf("historia") !== -1)
                                            return true;
                                        }
                                    }
                                    to="/laboratorio">
                                        Sobre Nosotros
                                        <span className="fas fa-angle-down arrow"></span>
                                </NavLink>
                                <ul className="submenu">
                                    <li className="sublink-container">
                                        <NavLink
                                            className="sublink"
                                            to="/laboratorio">
                                                Lab UX USM
                                        </NavLink>
                                    </li>
                                    <li className="sublink-container">
                                        <NavLink
                                            className="sublink"
                                            to="/metodologia">
                                                Metodología
                                        </NavLink>
                                    </li>
                                    <li className="sublink-container">
                                        <NavLink
                                            className="sublink"
                                            to="/equipo">
                                                Equipo
                                        </NavLink>
                                    </li>
                                    <li className="sublink-container">
                                        <NavLink
                                            className="sublink"
                                            to="/historia">
                                                Historia
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="link-container">
                                <NavLink
                                    exact
                                    className="link"
                                    activeClassName="active_class"
                                    to="/aprende-mas">
                                        Aprende Más
                                </NavLink>
                            </li>
                            {/*
                            <li className="link-container">
                                <NavLink
                                    exact
                                    className="link"
                                    activeClassName="active_class"
                                    to="/schedule">
                                        Agenda Hora
                                </NavLink>
                            </li>
                            */}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}

export default Navegacion;
